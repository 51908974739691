import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import type { OpenGraph } from 'next-seo/lib/types'

import { getPageSlugFromRouterPath } from '@cms/analytics/utils'
import { LAYOUT_SIZE } from '@cms/components/Layout/CMSLayout/CMSLayout'
import { contentfulImageToOpenGraph, getHrefLocaleSuffix } from '@cms/utils/utils'
import Main from '@components/Layout/Main'
import { PageFooter } from '@components/Layout/Page/PageFooter'
import { PageHeader } from '@components/Layout/Page/PageHeader'
import { Breadcrumbs } from '@knauf-group/ct-designs/components/core/Breadcrumbs'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import { usePageviewEvent } from '@knauf-group/ct-shared-nextjs/web/hooks'
import { getOrigin } from '@knauf-group/ct-shared-nextjs/web/utils/utils'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'

import type { PageProps } from './Page.types'

const getFallBackTitle = (pageFields): string | undefined => {
  const fallbackTitle = pageFields?.sectionBody?.find(
    (component) => !!component?.fields?.headline,
  )
  return fallbackTitle?.fields?.headline
}

const Page: React.FC<PageProps> = ({
  children,
  cmsPageFields,
  headerEntries,
  footerEntries,
  heading,
  breadcrumbLinks,
  isMiniFooter = false,
  isMiniHeader = false,
  isPageInPreviewMode,
  sx,
  appsConfig,
  hasUserContent,
}) => {
  const router = useRouter()
  const { t } = useTranslation('cms', { keyPrefix: 'common' })
  const analyticsPageName = getPageSlugFromRouterPath(router)
  const pageMetadata = cmsPageFields?.metadata
  const tags = (): string => {
    if (!pageMetadata?.tags?.length) {
      return ''
    }
    return pageMetadata.tags
      .map(({ sys: { id: tagId } }) => tagId.toLowerCase())
      .toString()
      .replaceAll(',', ';')
  }
  usePageviewEvent(analyticsPageName, tags(), hasUserContent)
  const pageFields = cmsPageFields?.fields
  const pageSeoFields = pageFields?.seoReference?.fields

  const siteName = t('metadata.openGraphSiteName')

  const title =
    pageSeoFields?.title ||
    heading ||
    getFallBackTitle(pageFields) ||
    t('metadata.seoDefaultTitle')
  const description = pageSeoFields?.metaDescription || t('metadata.seoDefaultDescription')
  const { locale } = router
  const localeSuffix = getHrefLocaleSuffix(locale)
  const origin = getOrigin()
  const canonical = `${origin}${localeSuffix}${router.asPath.split('?')[0].replace(/\/$/, '')}` // regex removes trailing slash. Also removing the params

  const styles: Styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      contain: 'paint',
      minHeight: 'calc(100vh - 64px)',
    },
    breadcrumb: {
      ...LAYOUT_SIZE,
      width: '100%',
      marginX: 'auto',
    },
  }

  const openGraphImages = (pageSeoFields?.openGraphImages as [])?.map((image) =>
    contentfulImageToOpenGraph(image),
  ) || [
    {
      url: `${origin}/images/knauf_og_1.png`,
      width: 441,
      height: 249,
      alt: siteName,
    },
    {
      url: `${origin}/images/knauf_og_2.png`,
      width: 2400,
      height: 1260,
      alt: siteName,
    },
    {
      url: `${origin}/images/knauf_og_3.png`,
      width: 2400,
      height: 1260,
      alt: siteName,
    },
    {
      url: `${origin}/images/knauf_og_4.png`,
      width: 2400,
      height: 1260,
      alt: siteName,
    },
  ]

  const openGraphDetails: OpenGraph = {
    type: 'website',
    locale,
    url: canonical,
    site_name: siteName,
    title,
    description,
    images: openGraphImages,
  }

  return (
    <Box id="page-container" sx={sx}>
      <PageHeader
        appsConfig={appsConfig}
        isPageInPreviewMode={isPageInPreviewMode}
        headerEntries={headerEntries}
        isMiniHeader={isMiniHeader}
      />
      <Toolbar data-cy="toolbar-header" />
      <Box sx={styles.container}>
        <NextSeo
          title={title}
          description={description}
          canonical={canonical}
          openGraph={openGraphDetails}
        />
        <Main>
          {!!breadcrumbLinks?.length && (
            <Container>
              <Breadcrumbs
                locale={locale}
                links={breadcrumbLinks}
                backLabel={t('slider.backButtonLabel')}
                nextLinkComponent={NextLink}
              />
            </Container>
          )}
          {children}
        </Main>
        <PageFooter footerEntries={footerEntries} isMiniFooter={isMiniFooter} />
      </Box>
    </Box>
  )
}

export default Page
